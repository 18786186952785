<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-1 dialogTitle-text">Task Info</div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleListTaskInfoModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
           <div
              class="text-center"
            >
              <div style="text-align: justify; font-size: 16px">
                <hr
                  style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
                />
                {{ this.ptoDetail.start_date_str }}
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />

                <br />
                <strong>Category: </strong>Task
                <br />
                <br />
                <strong>Description: </strong> {{ this.ptoDetail.note }}
                <hr
                  style="
                    height: 2px;
                    border-width: 0;
                    color: gray;
                    background-color: gray;
                  "
                />
              </div>
            </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
export default {
  name: "ListCalendarManagement",
  components: {},
  data() {
    return {
      loading: false,
      formLoading: false,
      ptoDetail: {},
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "calendarManagement/getShowListTaskInfo",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleListTaskInfoModal({ show: value });
      },
    },
    type() {
      return this.$store.state.calendarManagement.listTaskInfoModal.type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Task Added";
      } else {
        return "Task Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New Task";
        case "edit":
          return "Edit Event";
        default:
          return "";
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      toggleListTaskInfoModal: "calendarManagement/toggleListTaskInfoModal",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
      console.log("open modal");
      this.getptoDetail();
    },
    closeCallBackModal() {},

   getptoDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.ptoDetail = res.data.calendar_detail;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      Axios.request_GET(
        "/calendar/" + this.$store.state.calendarManagement.listTaskInfoModal.taskID + "/",
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    converTime(value) {
      if (value) {
        var temp = value.split(":");
        if (parseInt(temp[0]) > 12) {
          value = parseInt(temp[0]) - 12 + ":" + temp[1] + " PM";
        } else {
          value = temp[0] + ":" + temp[1] + " AM";
        }
      }

      return value;
    },
  },
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
</style>
